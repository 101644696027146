<template>
    <div class="body">
        <!-- 头部 -->
        <top></top>
        <!-- 返回个人中心的按钮 -->
        <div class="return_button_box">
            <div class="return_button_content">
                <button @click="fanhui">
                    <h4>返回个人中心</h4>
                </button> </div>
        </div> <!-- 内容 -->
        <div class="content_box">
            <div class="content">
                <!-- 内部的内容 -->
                <div class="interior">
                    <!-- 更改头像 -->
                    <div class="interior_content">
                        <!-- 左 -->
                        <div class="interior_content_left">
                            <label for="">头&nbsp;像：</label>
                        </div>
                        <!-- 右 改头像-->
                        <div class="interior_content_right">
                            <div class="head_box">
                                <!-- <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
                                    :show-file-list="false" :on-success="handleAvatarSuccess"
                                    :before-upload="beforeAvatarUpload" accept="image/*">
                                    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload> -->
                                <img :src="userimga" alt="">
                            </div>
                        </div>
                    </div>
                    <!-- 昵称 -->
                    <div class="nickname_box">
                        <!-- 左 -->
                        <div class="nickname_left">
                            <label for="">昵&nbsp;称：</label>
                        </div>
                        <!-- 右 -->
                        <div class="nickname_right">
                            <input type="text" v-model="value" :placeholder="username" />
                        </div>
                    </div>
                    <!-- 账号 -->
                    <div class="nickname_box">
                        <!-- 左 -->
                        <div class="nickname_left">
                            <label for="">账&nbsp;号：</label>
                        </div>
                        <!-- 右 -->
                        <div class="nickname_right">
                            <p>{{cellphone}}</p>
                        </div>
                    </div>
                    <!-- 修改完成 -->
                    <div class="amend_button_box">
                        <!-- 左 -->
                        <div class="amend_button_left"></div>
                        <!-- 右 -->
                        <div class="amend_button_right">
                            <button>修改完成</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <bottom class="o"></bottom>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                imageUrl: "",
                value: "",
                valueaaa: [],
                userimga: [],
                username: '',
                vellphone: '',

            };
        },
        // 页面初始化加载
        created() {
            //从sessionStorage中获取用户的类型
            var infostr = sessionStorage.getItem("key");
            var keyaaa = JSON.parse(infostr);
            this.valueaaa = keyaaa;
            this.cellphone = this.valueaaa.phone;
            this.userimga = this.valueaaa.headimgurl;
            this.username = this.valueaaa.realname;
        },
        methods: {
            // 返回个人中心按钮
            fanhui() {
                this.$router.push("/Personal");
            },
            handleAvatarSuccess(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === "image/jpeg";
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error("上传头像图片只能是 JPG 格式!");
                }
                if (!isLt2M) {
                    this.$message.error("上传头像图片大小不能超过 2MB!");
                }
                return isJPG && isLt2M;
            },
        },
    };
</script>
<style scoped>
    .body {
        width: 100%;
        min-width: 1200px;
        background-color: #fafafa;
        /* border: 1px transparent solid; */
    }
    
    .return_button_box {
        width: 100%;
        min-width: 1200px;
        height: 85px;
        margin-top: 60px;
    }
    
    .return_button_content {
        width: 1200px;
        margin: auto;
        height: 85px;
        display: flex;
        align-items: center;
    }
    
    .return_button_content button {
        width: 159px;
        height: 44px;
        border: 1px solid #999999;
        background: none;
        font-size: 16px;
        color: #999999;
    }
    /* 内容 */
    
    .content_box {
        width: 100%;
        min-width: 120px;
    }
    
    .content {
        width: 1200px;
        height: 590px;
        margin: auto;
        background-color: white;
        /* background-color: red; */
        padding-top: 30px;
    }
    /* 内部的内容 */
    
    .interior {
        width: 89%;
        margin: auto;
    }
    /* 头像 */
    
    .interior_content {
        width: 100%;
        display: flex;
    }
    /* 左 */
    
    .interior_content_left {
        width: 62px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /* 右 */
    
    .interior_content_right {
        width: 90%;
        margin-left: 2%;
    }
    /* 改头像 */
    
    .head_box {
        width: 85px;
        height: 85px;
        border: 1px red solid;
        border-radius: 50%;
    }
    
    .head_box img {
        width: 85px;
        height: 85px;
        /* border: 1px red solid; */
        border-radius: 50%;
    }
    
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    
    .el-icon-plus {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
    }
    
    .el-icon-plus::before {
        content: "点击上传";
    }
    
    .avatar-uploader-icon {
        width: 85px !important;
        height: 85px !important;
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 85px !important;
        color: white !important;
        font-size: 16px;
        text-align: center;
    }
    
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    /* 昵称 */
    
    .nickname_box {
        width: 100%;
        height: 64px;
        margin-top: 10px;
        display: flex;
    }
    /* 左 */
    
    .nickname_left {
        width: 62px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /* 右 */
    
    .nickname_right {
        width: 35%;
        margin-left: 1%;
        display: flex;
        align-items: center;
    }
    
    .nickname_right input {
        width: 100%;
        line-height: 44px;
        border: 1px #cccccc solid;
        text-indent: 14px;
        font-size: 16px;
        color: #333333;
        border-radius: 5px;
    }
    /* 账号 */
    
    .nickname_right p {
        text-indent: 14px;
        font-size: 16px;
        color: #333333;
    }
    /* 修改完成的按钮 */
    
    .amend_button_box {
        width: 100%;
        height: 40px;
        display: flex;
        margin-top: 20px;
    }
    /* 左 */
    
    .amend_button_left {
        width: 62px;
        height: 64px;
    }
    
    .amend_button_right {
        width: 90%;
        margin-left: 2%;
        display: flex;
        align-items: center;
    }
    
    .amend_button_box button {
        width: 133px;
        height: 44px;
        border: 1px solid #f62959;
        border-radius: 5px;
        background: none;
        color: #f62959;
        margin-left: 12px;
        font-size: 16px;
    }
    /* 底部 */
    
    .o {
        margin-top: 40px;
    }
</style>
<style>
    .el-upload {
        border-radius: 50%;
        overflow: hidden;
        border: 1px #cccccc solid;
    }
    
    .avatar {
        width: 85px !important;
        height: 85px !important;
    }
</style>